


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "@/config";
import Axios from "axios";
import _ from "lodash";

import { HollowDotsSpinner } from "epic-spinners";
import '@/vuebar';
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import CommunicatioMixin from "@/mixins/communication.mixin"
import DisplayFiles from "@/views/DisplayDocument.vue";
import vue2Dropzone from "vue2-dropzone";
import striptags from "striptags";
import SelectBorrowerForPos from "@/views/SelectBorrowerForPos.vue";
import BootstrapVue from "bootstrap-vue";
import CheckCriticalFields from "@/views/CheckCriticalFields.vue";
import ChangeProcessorOfRecordModal from "@/views/ChangeProcessorOfRecordModal.vue";
import ConfirmUnassignBorrowerModal from "@/views/ConfirmUnassignBorrowerModal.vue";
import ViewDocument from "@/views/ViewDocument.vue";

let  w = window as any;
const Vuebar = w.Vuebar as any;

Vue.use(Vuebar);
Vue.use(BootstrapVue);

@Component({
  components: {
    HollowDotsSpinner,
    vueDropzone: vue2Dropzone,
    DisplayFiles,
    SelectBorrowerForPos,
    CheckCriticalFields,
    ChangeProcessorOfRecordModal,
    ConfirmUnassignBorrowerModal,
    ViewDocument,
  }
})
export default class BorrowerDetails extends mixins(CommonMixin,CommunicatioMixin) {
  public borrower: any = {};
  public processor: Object = null;
  public titleInformation = {};
  public createdOn = "";
  public borrowerDocuments = {};
  public allDocuments = [];
  public documentFiles = [];
  public dotLoader = false;
  public exportFnmEnable = false;
  public documentName = "";
  public documentType = "";
  public document: any = "";
  public attach = [];
  public cleartoclose = [];
  public voe = [];
  public voeresponse = [];
  public documentsName: any = [];
  public files = [];
  public activity: any = [];
  public conditionName: any = [];
  public docs: any = [];
  public borrowerContact = {};
  public dropzoneOptions = {
    url: "https://httpbin.org/post",
    thumbnailWidth: 150,
    maxFilesize: 50,
    headers: { "My-Awesome-Header": "header value" }
  };
  public openBorrowerSelectionModal = false;
  public isMismo: boolean = null;
  public lookingAtDocument = null;
  public selectedCommunication: any = {};
  public currentDocument;
  public documentVersionInfo: any = {};

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  get loanTxnId() {
    return this.$route.query.loanTxnId;
  }

  public async getArchivedBorrowerDetails() {
    this.dotLoader = true;
    this.documentsName = [];
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getArchivedBorrowerDetails",
        {
          loanTxnId: this.$route.query.loanTxnId,
          type: this.userType
        });

      this.borrower = response.data.borrower;
      this.processor = response.data.processor;

      if (response.data.borrowerContact != null)
        this.borrowerContact = response.data.borrowerContact;
      this.document = response.data.document;
      this.document.documents.forEach(e => {
        const hasFileHistoryForBorrower = !!_.get(e, 'fileHistoryForBorrower', []).filter(list => !_.isEmpty(_.get(list, 'fileHistory'))).length;

        let obj: any = {
          categoryName: e.categoryName,
          id: e._id,
          categoryType: e.categoryType,
          lastFileHistoryId: null,
          hasFileHistoryForBorrower,
        };
        if (e.fileHistory.length > 0) {
          let files = e.fileHistory[e.fileHistory.length - 1].files;
          obj.files = files;
          obj.lastFileHistoryId = e.fileHistory[e.fileHistory.length - 1]._id;
        }
        this.documentsName.push(obj);
      });
      this.docs = response.data.docs;
      if (response.data.activity.length == 0) {
        this.activity = response.data.activity[0];
      } else {
        this.activity =
          response.data.activity[response.data.activity.length - 1].taskName == 'Denial'?
          response.data.activity[response.data.activity.length - 1].taskName:response.data.activity[response.data.activity.length - 1].taskRef
          this.conditionName = response.data.activity[response.data.activity.length -1].conditionName;
      }

      this.dotLoader = false;
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }
  public showDocumentName(docName, docType) {
    this.$modal.show("modalForShowDocumentName");
    this.documentName = docName;
    this.documentType = docType;
  }
  // public displayFiles(data) {
  //   this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
  //     null,
  //     [data],
  //     null
  //   );
  // }
  public displayDocument(data) {
    this.$refs.dfd["setInitialValuesForAttachmentsAndComments"](
      null,
      data,
      null
    );
  }

  getDocumentById(id) {
    const documents = _.get(this, 'document.documents', []);

    return documents.find(document => _.get(document, '_id') === id);
  }

  displayDocumentNew(document) {
    if (!document) { return; }

    const fullDocument = this.getDocumentById(_.get(document, 'id'));

    this.lookingAtDocument = fullDocument;
    this.$modal.show('viewDocumentModal');
  }

  async deleteDocumentVersion({ documentId, fileHistoryId, wetSignBorrower = null }) {
    if (!documentId) {
      return;
    }

    if (!fileHistoryId) {
      return;
    }

    await this.deleteDocumentService(documentId, fileHistoryId, wetSignBorrower);
    this.$modal.hide('deleteDocumentConfirmation');

    EventBus.$emit('documentVersionDeleted', fileHistoryId);

    await this.getArchivedBorrowerDetails();
  }

  async deleteDocument({ id, lastFileHistoryId }) {
    await this.deleteDocumentService(id, lastFileHistoryId);
    this.$modal.hide('deleteDocumentConfirmation');
  }
  
  public async deleteDocumentService(documentId, fileHistoryId, wetSignBorrower = null) {

    console.log(documentId, fileHistoryId);

    this.dotLoader = true;
    try {
      await Axios.post(
        BASE_API_URL + "superAdmin/deleteBorrowerDocument",
        {
          loanTxnId: this.$route.query.loanTxnId,
          documentId,
          fileHistoryId,
          wetSignBorrower,
        });

      await this.getArchivedBorrowerDetails();
      this.dotLoader = false;
    } catch (error) {
      console.error(error);
      this.dotLoader = false;
    }
  }

//truncate a comments 
public removeTagsAndTruncate(strg, length, ending) {
        if (strg) {
            let str = striptags(strg, [], " ").replace(/\s\s+/g, " ");
            if (length == null) {
                length = 100;
            }
            if (ending == null) {
                ending = "...";
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        } else return "";
    }
  public async downlaodAllUploadedDocs() {
    let countFalse = false;
    let arr = [];
    this.documentsName.forEach(doc => {
      if (doc.files && doc.files.length > 0) {
        countFalse = true;
        doc.files.forEach(file => {
          arr.push({
            path: file.path,
            name: file.originalName
              ? file.originalName
              : this.getFileName(file.path)
          });
        });
      }
    });
    if (!countFalse) {
      this.$snotify.clear();
      this.$snotify.error("No document uploaded yet!");
      return;
    }
    //Sending the data to mixins
    this.$refs.dfd["downloadCurrentReviewDocument"](arr);
  }

 /*********************************************************************************************************************************************************************
  *                                                          Select Borrower for POS Flow                                                                             *
  *********************************************************************************************************************************************************************/
  public selectPosBorrower() {
    if (this.borrower) {
      this.$router.push({
        path: this.$getCurrentUserTypePath("pos-questionnaire"),
        query: {
          loanTxnId: this.borrower.loanTxnId,
          borrowerId: this.borrower.borrowerInfo[
            this.borrower.primaryBorrowerIndex
          ].userId
        }
      });
    }
  }

  public getFileName(path) {
    let index = path.lastIndexOf("/");
    let fileName = path.substring(index + 1, path.length);
    return fileName;
  }

  async getEnumerationType() {
    const { data: { enumerationType } } = await Axios.post(
      BASE_API_URL + 'los/getEnumerationType',
      { loanTxnId: this.$route.query.loanTxnId, }
    );

    return enumerationType;
  }

  public async skipUrlaValidation() {
    try {
      let confirm = false;

      await this.$dialog
        .confirm("Are you sure you want to skip URLA validation?")
        .then(() => {
          confirm = true;
        })
        .catch(() => {
          confirm = false;
        });

      if (!confirm) {
        return;
      }

      let response = await Axios.post(BASE_API_URL + "broker/updateSkipUrlaSettings", {
        loanTxnId: this.$route.query.loanTxnId,
        skipUrlaValidation: true,
      });

      this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  public async skipUrlaSubmission() {
    try {
      let confirm = false;

      await this.$dialog
        .confirm("Are you sure you want to skip URLA submission?")
        .then(() => {
          confirm = true;
        })
        .catch(() => {
          confirm = false;
        });

      if (!confirm) {
        return;
      }

      let response = await Axios.post(BASE_API_URL + "broker/updateSkipUrlaSettings", {
        loanTxnId: this.$route.query.loanTxnId,
        skipUrlaSubmission: true,
      });

      this.$snotify.success(response.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  public openUnassignBorrowerFromCommunicationModal(communication) {
    this.selectedCommunication = communication;
    this.$modal.show('confirmUnassignBorrower');
  }

  public onUnassignBorrowerFromCommunication() {
    this.selectedCommunication = {};
    this.getCommunicationHistory(this.borrower.loanTxnId);
  }

  public async openDeleteDocumentModal(doc) {
    this.currentDocument = doc;
    this.$modal.show('deleteDocumentConfirmation');
  }
  
  public async openDeleteDocumentVersionModal(documentInfo) {
    this.currentDocument = null;
    this.documentVersionInfo = documentInfo;
    this.$modal.show('deleteDocumentConfirmation');
  }

  public async acceptedDeleteDocument(doc) {
    if (doc) {
      await this.deleteDocument(doc);
    } else {
      await this.deleteDocumentVersion(this.documentVersionInfo);
    }
  }

  async mounted() {
    this.isMismo = (await this.getEnumerationType()) === 'mismo';

    this.getArchivedBorrowerDetails();
  }
}
