




































import Axios from "axios";
import _ from "lodash";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

@Component({
  components: {
  },
})
export default class ChangeProcessorOfRecordModal extends Vue {

  @Prop({ required: true })
  public loanTxnId: string;

  public processors: Array<object> = [];
  public selectedProcessorId: string = null;
  public selectedPreviousProcessorId: string = null;

  public async getWemloProcessorList() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.get(BASE_API_URL + "wemloprocessor/getAllWemloProcessorList");

      this.processors = _.sortBy(response.data.wemloStaffList, ['lastName', 'firstName']);
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async getProcessorOfRecord() {
    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.get(BASE_API_URL + "wemloprocessor/getProcessorOfRecord",
        {
          params: {
            loanTxnId: this.loanTxnId,
          }
        }
      );

      if (response.data.processor) {
        this.selectedProcessorId = response.data.processor.userId;
        this.selectedPreviousProcessorId = response.data.processor.userId;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  public async changeProcessorOfRecord() {
    if (!this.selectedProcessorId) {
      this.$snotify.error("Please select Processor ");
      return;
    }

    if (this.selectedProcessorId === this.selectedPreviousProcessorId) {
      this.$snotify.error("Please select other Processor");
      return;
    }

    try {
      this.$store.state.wemloLoader = true;

      let response = await Axios.post(BASE_API_URL + "wemloprocessor/changeProcessorOfRecord",
        {
          selectedProcessorId: this.selectedProcessorId,
          selectedPreviousProcessorId: this.selectedPreviousProcessorId,
          loanTxnId: this.loanTxnId,
        }
      );
      
      this.selectedPreviousProcessorId = this.selectedProcessorId;

      this.$modal.hide('changeProcessorOfRecord');
      this.$snotify.success(response.data.message);
      this.$emit("change")
    } catch (error) {
      console.log(error);
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }

  async mounted() {
    await this.getProcessorOfRecord();
    await this.getWemloProcessorList();
  }
}
